@import '../../assets/scss/abstracts/abstracts';

.shipmentTransitviewContainer {
  padding: 1rem;
  font-family: "EtihadAltis-Book", sans-serif;

  .shipment-info {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
  }

  .card {
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    .shipment-transitview-summary {
      font-family: "EtihadAltis-Medium", sans-serif;
      margin-left: 7rem;
      border-radius: 2px;
      background: transparent;
      flex-wrap: nowrap;
      padding: 11px 2px;
    }

    .row {
      margin-bottom: 1rem;

      h5 {
        color: #55656f;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 16px;
        color: $textcolor;
        font-weight: 300;
        margin: 0;
      }

      .estimated-time {
        display: flex;
        flex-wrap: wrap; // Wrap items on smaller screens
        column-gap: 1rem;
        row-gap: 0.5rem;

        .text-strike {
          text-decoration: line-through;
          color: #070707; // Muted color for original time
          margin-right: 0.5rem;
        }

        .delay-hours {
          color: rgb(243, 94, 94);
        }

        .badge {
          &.bg-delayed {
            background-color: rgba(253, 232, 213, 1);
            color: #0e0000;
            font-size: 1rem;
            border-radius: 5px;
          }
        }
      }
    }

    .timeline {
      position: relative;

      .timeline::before {
        content: "";
      }

      .timeline-item {
        position: relative;
        margin-bottom: 2rem;

        &.solid-line::before {
          content: "";
          position: absolute;
          left: 3px;
          top: 3px;
          bottom: -31px;
          width: 2px;
          background: $etihadNewDarkBlue;
        }

        &.dotted-line::before {
          content: "";
          position: absolute;
          left: 3px;
          top: 3px;
          bottom: -31px;
          border-left: 2px dotted $etihadNewDarkBlue;
        }

        .timeline-dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          left: -2px;
          top: 0;

          &.solid {
            background: $etihadNewDarkBlue;
          }

          &.half {
            background: linear-gradient(to top, $etihadNewDarkBlue 50%, #fff 50%);
            border: 2px solid $etihadNewDarkBlue;
          }

          &.empty {
            background: #fff;
            border: 2px solid $etihadNewDarkBlue;
          }
        }

        .timeline-heading {
          font-size: 1.1rem;
          text-align: start;
          margin-left: 44px;
          color: #4e4c4a;
        }

        .timeline-content {
          margin-left: 3rem;
          display: flex;
          flex-wrap: wrap;
          column-gap: 20px;
          row-gap: 1rem;

          .card {
            padding: 20px;
            border: 1px solid #ddd;
            background: #fffefe;
            width: 100%;
            max-width: 207px;
            border-radius: 4px;
            font-size: 12px;

            p {
              margin: 0.2rem 0;
            }
            .description-section {
              font-size: 16px;
              color: $textcolor;
            }
            .datetime-section {
              font-size: 14px;
              color: #55656f;
              white-space: pre-wrap;
            }

            .card-text-strike {
              text-decoration: line-through;
              color: #070707;
              margin-right: 0.5rem;
            }

            .badge {
              font-size: 14px;
              margin-top: 24px;
              padding: 7px 30px 7px 11px;
              align-content: center;
              border-radius: 5px;
              font-weight: 500;

              &.bg-delayed {
                background-color: rgba(253, 232, 213, 1);
                color: #0e0000;
                margin-top: 0.4rem;
              }

              &.bg-confirmed {
                background-color: rgb(216, 231, 214);
                color: #0e0000;
              }

              &.bg-planned {
                background-color: rgba(206, 229, 250, 1);
                color: #0e0000;
              }
            }
          }
        }
      }
    }
  }

  // Responsive Styles
  @media (max-width: 768px) {
    .card {
      padding: 1rem;

      .row {
        h5 {
          font-size: 1rem;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }

    .timeline {
      .timeline-content {
        margin-left: 2rem;
      }
    }
  }

  @media (max-width: 576px) {
    .card {
      padding: 0.5rem;

      .row {
        h5 {
          font-size: 0.9rem;
        }

        p {
          font-size: 0.8rem;
        }
      }
    }

    .timeline {
      .timeline-dot {
        width: 10px;
        height: 10px;
      }

      .timeline-heading {
        font-size: 0.9rem;
        margin-left: 36px;
      }

      .timeline-content {
        margin-left: 1.5rem;

        .card {
          width: 100%;
        }
      }
    }
  }
}
